<template>
  <portal to="footer">
    <div class="sm:pb-32">
      <div class="sm:fixed sm:bottom-0 sm:left-0 w-full">
        <footer class="slide-nav container mx-auto">
          <div class="slide-nav__inner py-3 sm:py-4 md:py-6">
            <div class="flex flex-row-reverse items-center justify-between">
              <div>
                <span
                  v-if="false && nextSlide"
                  class="mx-2 text-sm block text-gray-700">{{ nextSlide.headline }}</span>
                <transition name="fade">
                  <next-button
                    v-if="nextSlide && !isHidden"
                    :disabled="!isValid || !isValid && !nextSlide"
                    @click.prevent.native="next()">
                    Neste
                  </next-button>
                </transition>
              </div>
              <div>
                <span
                  v-if="false && prevSlide"
                  class="my-2 text-sm block text-gray-700">{{ prevSlide.headline }}</span>
                <default-button
                  v-if="prevSlide"
                  :disabled="prevSlide === null"
                  @click.prevent.native="prev()"
                  class="underline py-3"
                  skinny>
                  Tilbake
                </default-button>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    isValid: {
      type: Boolean,
      default: false
    },

    isHidden: {
      type: Boolean,
      default: false
    },

    prevSlide: {
      type: [Object, null],
      default () {
        return null
      }
    },

    nextSlide: {
      type: [Object, null],
      default () {
        return null
      }
    },

    next: {
      type: Function,
      default: () => {}
    },

    prev: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .slide-nav {
    &__inner {
      @apply border-t;
      @apply border-gray-300;
      border-top-width: var(--slide-nav-border-width);
    }
  }
</style>
